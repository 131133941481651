"use strict";
exports.__esModule = true;
exports.COWindow = void 0;
var new_co_generator_1 = require("./new-co.generator");
var COWindow = (function () {
    function COWindow() {
        this.opened = false;
        this.url = null;
        this.onlyThis = false;
        this.content = null;
        this.contentElement = null;
        this.minHeight = null;
        this.classes = {};
        this.title = null;
        this.previus = null;
        this.onBeforeClose = function () {
        };
        this.onClose = function () {
        };
        this.width = 'auto';
        this.data = {};
        if (typeof $.ui === 'undefined') {
            console.error("jQuery UI nincs betöltve.");
            return;
        }
        this.id = new_co_generator_1.COGenerator.UniqueID.create();
        this.data = {};
    }
    COWindow.getLastZIndex = function () {
        var dialogs = document.querySelectorAll('.ui-dialog');
        var lastDialog = dialogs.item(dialogs.length - 1);
        if (lastDialog != null && lastDialog.style.zIndex != '') {
            return parseInt(lastDialog.style.zIndex);
        }
        else {
            return 20000;
        }
    };
    ;
    COWindow.prototype.setUrl = function (url) {
        this.url = url;
        return this;
    };
    ;
    COWindow.prototype.setClasses = function (classes) {
        this.classes = classes;
        return this;
    };
    ;
    COWindow.prototype.setMinHeight = function (val) {
        this.minHeight = val;
        return this;
    };
    ;
    COWindow.prototype.setContent = function (content) {
        this.content = content;
        return this;
    };
    ;
    COWindow.prototype.setContentElement = function (content) {
        if (this.opened) {
            document.querySelector('#' + this.id).appendChild(content);
        }
        else {
            this.contentElement = content;
        }
        return this;
    };
    ;
    COWindow.prototype.setTitle = function (title) {
        this.title = title;
        if (this.opened) {
            $('#' + this.id).dialog('option', 'title', this.title);
        }
        return this;
    };
    ;
    COWindow.prototype.setData = function (data) {
        this.data = data;
        return this;
    };
    ;
    COWindow.prototype.setOnlyThis = function () {
        this.onlyThis = true;
        return this;
    };
    ;
    COWindow.prototype.setOnBeforeClose = function (callback) {
        this.onBeforeClose = callback;
        return this;
    };
    ;
    COWindow.get = function (el) {
        var id = el.closest('.ui-dialog-content').getAttribute('id');
        var w = COWindow.getById(id);
        return w;
    };
    COWindow.getById = function (id) {
        for (var _i = 0, _a = COWindow.windows; _i < _a.length; _i++) {
            var w = _a[_i];
            if (w.getId() == id) {
                return w;
            }
        }
        return null;
    };
    COWindow.prototype.getId = function () {
        return this.id;
    };
    COWindow.getLast = function () {
        if (COWindow.windows.length == 0) {
            return null;
        }
        var w = COWindow.windows[COWindow.windows.length - 1];
        return w;
    };
    COWindow.prototype.setWidth = function (width) {
        this.width = width;
        return this;
    };
    ;
    COWindow.prototype.setOverlayClick = function () {
        this.getElement().parentElement.nextElementSibling.addEventListener('click', function () {
            var w = COWindow.getLast();
            if (w) {
                w.close();
            }
        });
    };
    COWindow.prototype.open = function (afterOpened) {
        if (afterOpened === void 0) { afterOpened = null; }
        var prevWindow = COWindow.getLast();
        var overlay = null;
        if (prevWindow) {
            overlay = (prevWindow.getElement().parentElement.nextElementSibling);
        }
        var that = this;
        var newZIndex = COWindow.getLastZIndex();
        if (this.url != null) {
            $.ajax({
                url: this.url,
                type: 'POST',
                dataType: 'html',
                data: this.data,
                success: function (data, textStatus, jqXHR) {
                    if (overlay) {
                        overlay.style.opacity = "0";
                    }
                    document.body.classList.add('co-window-opened');
                    COWindow.windows.push(that);
                    $('<div></div>').attr('id', that.id).css({ display: 'none' }).appendTo('body').html(data);
                    $('#' + that.id).dialog({
                        closeOnEscape: true,
                        title: that.title,
                        modal: true,
                        minHeight: that.minHeight,
                        classes: that.classes,
                        width: that.width,
                        beforeClose: function (el) {
                            that.close();
                        }
                    });
                    that.setOverlayClick();
                    that.opened = true;
                    if (afterOpened) {
                        afterOpened.call(that);
                    }
                    setTimeout(function () {
                        that.center();
                        $('#' + that.id).parent().css('z-index', newZIndex + 2);
                        $('#' + that.id).parent().next().css('z-index', (newZIndex + 1).toString());
                    }, 10);
                }
            });
        }
        else if (this.contentElement) {
            if (overlay) {
                overlay.style.opacity = "0";
            }
            COWindow.windows.push(this);
            $('<div></div>').attr('id', that.id).css({ display: 'none' }).appendTo('body').append(this.contentElement);
            $('#' + this.id).dialog({
                closeOnEscape: true,
                title: that.title,
                classes: this.classes,
                modal: true,
                minHeight: this.minHeight,
                width: 'auto',
                beforeClose: function (el) {
                    that.close();
                }
            });
            document.body.classList.add('co-window-opened');
            that.opened = true;
            setTimeout(function () {
                that.center();
                $('#' + that.id).parent().css('z-index', newZIndex + 2);
                $('#' + that.id).parent().next().css('z-index', (newZIndex + 1).toString());
            }, 10);
            if (afterOpened) {
                afterOpened.call(that);
            }
            that.setOverlayClick();
        }
        else {
            if (overlay) {
                overlay.style.opacity = "0";
            }
            COWindow.windows.push(this);
            $('<div></div>').attr('id', that.id).css({ display: 'none' }).appendTo('body').html(this.content);
            $('#' + this.id).dialog({
                closeOnEscape: true,
                title: that.title,
                classes: this.classes,
                modal: true,
                minHeight: this.minHeight,
                width: 'auto',
                beforeClose: function (el) {
                    that.close();
                }
            });
            document.body.classList.add('co-window-opened');
            that.opened = true;
            setTimeout(function () {
                that.center();
                $('#' + that.id).parent().css('z-index', newZIndex + 2);
                $('#' + that.id).parent().next().css('z-index', (newZIndex + 1).toString());
            }, 10);
            if (afterOpened) {
                afterOpened.call(that);
            }
            that.setOverlayClick();
        }
        return this;
    };
    ;
    COWindow.closeAll = function () {
        for (var i = 0; i < COWindow.windows.length; i++) {
            var w = COWindow.windows[i];
            w.close();
        }
    };
    ;
    COWindow.prototype.getElement = function () {
        return document.querySelector('#' + this.id);
    };
    COWindow.prototype.close = function () {
        this.onBeforeClose();
        $('#' + this.id).dialog().remove();
        var nWindows = new Array();
        for (var i = 0; i < COWindow.windows.length; i++) {
            var t = COWindow.windows[i];
            if (t.id != this.id) {
                nWindows.push(t);
            }
        }
        COWindow.windows = nWindows;
        if (nWindows.length == 0) {
            document.body.classList.remove('co-window-opened');
        }
        var prevWindow = COWindow.getLast();
        var overlay = null;
        if (prevWindow) {
            overlay = prevWindow.getElement().parentElement.nextElementSibling;
            if (overlay) {
                overlay.style.opacity = "1";
            }
        }
    };
    COWindow.prototype.center = function () {
        $("#" + this.id).dialog({
            position: {
                my: "center",
                at: "center",
                of: window,
                collision: 'fit'
            }
        });
        if (this.getElement()) {
            (this.getElement().parentElement.nextElementSibling).style.zIndex = (parseInt((this.getElement().parentElement).style.zIndex) - 1).toString();
        }
    };
    COWindow.centerAll = function () {
        COWindow.windows.forEach(function (w) {
            w.center();
        });
    };
    COWindow.windows = new Array();
    return COWindow;
}());
exports.COWindow = COWindow;
