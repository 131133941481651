"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.COImage = void 0;
var custom_elements_1 = require("./custom-elements");
var new_co_imageloader_1 = require("./new-co.imageloader");
var new_co_inviewport_1 = require("./new-co.inviewport");
var co_lightbox_1 = require("./co.lightbox");
var new_co_generator_1 = require("./new-co.generator");
var COImageSize;
(function (COImageSize) {
    COImageSize[COImageSize["contain"] = 0] = "contain";
    COImageSize[COImageSize["cover"] = 1] = "cover";
})(COImageSize || (COImageSize = {}));
var COImage = (function (_super) {
    __extends(COImage, _super);
    function COImage(element) {
        if (element === void 0) { element = null; }
        var _this = _super.call(this, element) || this;
        _this.onLoad = null;
        _this.size = COImageSize.cover;
        _this.lightboxGroup = null;
        if (element) {
            _this.init();
        }
        return _this;
    }
    COImage.prototype.init = function () {
        this.createContainer();
        this.createImageContainer();
        this.createLoader();
        if (this.element.getAttribute('lazyload') == 'false') {
            this.loadImage();
        }
    };
    COImage.prototype.setOnLoad = function (onLoad) {
        this.onLoad = onLoad;
    };
    COImage.prototype.createContainer = function () {
        var container = document.createElement('div');
        container.classList.add('co-image-container');
        this.element.appendChild(container);
    };
    COImage.prototype.createImageContainer = function () {
        var image = document.createElement('div');
        image.classList.add('image');
        this.element.querySelector('.co-image-container').appendChild(image);
        var img = document.createElement('img');
        if (this.element.hasAttribute('alt')) {
            img.alt = this.element.getAttribute('alt');
            this.element.removeAttribute('alt');
        }
        image.appendChild(img);
    };
    COImage.prototype.createLoader = function () {
        var loader = document.createElement('div');
        loader.classList.add('loader');
        var icon = document.createElement('div');
        icon.classList.add('icon');
        icon.innerHTML = '<svg version="1.1" id="L5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">\n' +
            '  <circle fill="" stroke="none" cx="26" cy="50" r="6" transform="translate(0 -3.31716)">\n' +
            '    <animateTransform attributeName="transform" dur="1s" type="translate" values="0 15 ; 0 -15; 0 15" repeatCount="indefinite" begin="0.1"></animateTransform>\n' +
            '  </circle>\n' +
            '  <circle fill="" stroke="none" cx="50" cy="50" r="6" transform="translate(0 -6.21144)">\n' +
            '    <animateTransform attributeName="transform" dur="1s" type="translate" values="0 10 ; 0 -10; 0 10" repeatCount="indefinite" begin="0.2"></animateTransform>\n' +
            '  </circle>\n' +
            '  <circle fill="" stroke="none" cx="74" cy="50" r="6" transform="translate(0 -4.89428)">\n' +
            '    <animateTransform attributeName="transform" dur="1s" type="translate" values="0 5 ; 0 -5; 0 5" repeatCount="indefinite" begin="0.3"></animateTransform>\n' +
            '  </circle>\n' +
            '</svg>';
        loader.appendChild(icon);
        this.element.querySelector('.co-image-container').appendChild(loader);
        this.element.querySelector('.co-image-container').classList.add('loading');
        if (this.element.hasAttribute('size')) {
            if (this.element.getAttribute('size') == 'cover') {
                this.size = COImageSize.cover;
            }
            if (this.element.getAttribute('size') == 'contains' || this.element.getAttribute('size') == 'contain') {
                this.size = COImageSize.contain;
            }
        }
        if (this.element.hasAttribute('lightbox-image-url')) {
            this.lightBoxIndex = [].indexOf.call(document.querySelectorAll('co-image[lightbox-group]'), this.element);
            if (this.lightBoxIndex == -1) {
                this.lightBoxIndex = 0;
            }
            if (this.getLightbox().getItems().length <= this.lightBoxIndex) {
                this.getLightbox().addImage(this.element.getAttribute('lightbox-image-url'));
            }
            this.element.classList.add('lightbox');
            this.element.addEventListener('click', this.click.bind(this));
        }
        if (this.size == COImageSize.cover) {
            this.element.querySelector('.image').style.backgroundSize = 'cover';
        }
        if (this.size == COImageSize.contain) {
            this.element.querySelector('.image').style.backgroundSize = 'contain';
        }
    };
    COImage.prototype.getLightbox = function () {
        var group = this.getLightboxGroup();
        if (COImage.lightboxes.has(group) == false) {
            COImage.lightboxes.set(group, new co_lightbox_1.COLightBox.Controller());
        }
        var lightbox = COImage.lightboxes.get(group);
        return lightbox;
    };
    COImage.prototype.getLightboxGroup = function () {
        if (this.lightboxGroup) {
            return this.lightboxGroup;
        }
        if (this.element.hasAttribute('lightbox-group')) {
            this.lightboxGroup = this.element.getAttribute('lightbox-group');
        }
        else {
            this.lightboxGroup = new_co_generator_1.COGenerator.UniqueID.create();
        }
        return this.lightboxGroup;
    };
    COImage.prototype.click = function () {
        this.getLightbox().open(this.lightBoxIndex);
    };
    COImage.prototype.onAttributeChange = function () {
        var that = this;
        if (this.element.hasAttribute('src') && this.element.getAttribute('src')) {
            this.url = this.element.getAttribute('src');
        }
        if (this.element.hasAttribute('background') && this.element.getAttribute('background')) {
            this.url = this.element.getAttribute('background');
        }
        if (this.url) {
            new_co_inviewport_1.COInViewport.Controller.add(new new_co_inviewport_1.COInViewport.Item().setElement(this.element).setCallback(function () {
                that.loadImage();
            }));
        }
        else {
            this.element.querySelector('.co-image-container').classList.remove('loading');
            this.element.querySelector('.co-image-container').classList.add('empty');
            this.element.querySelector('.image').style.backgroundImage = '';
            this.element.querySelector('.image img').src = '';
        }
    };
    COImage.prototype.loadImage = function () {
        var that = this;
        that.element.querySelector('.co-image-container').classList.add('loading');
        new new_co_imageloader_1.COImageLoader.Controller().add(that.url, function (url, width, height) {
            that.element.querySelector('.image').style.backgroundImage = 'url(' + url + ')';
            that.element.querySelector('img').src = url;
            that.element.querySelector('.co-image-container').classList.remove('loading');
            that.element.querySelector('.co-image-container').classList.remove('empty');
            if (that.size == COImageSize.contain) {
                if (that.element.clientHeight <= height && that.element.clientWidth <= width) {
                    that.element.querySelector('.image').style.backgroundSize = 'contain';
                }
                else {
                    if (that.element.clientHeight > height && that.element.clientWidth > width) {
                        that.element.querySelector('.image').style.backgroundSize = 'auto';
                    }
                    else {
                        if (that.element.clientHeight < height) {
                            that.element.querySelector('.image').style.backgroundSize = 'auto 100%';
                        }
                        else {
                            that.element.querySelector('.image').style.backgroundSize = '100% auto';
                        }
                    }
                }
            }
            if (that.onLoad) {
                that.onLoad();
            }
        }).execute();
    };
    COImage.prototype.onAdded = function () {
    };
    COImage.lightboxes = new Map();
    return COImage;
}(custom_elements_1.CustomElements.CustomElementBase));
exports.COImage = COImage;
