"use strict";
exports.__esModule = true;
exports.CO = void 0;
var CO;
(function (CO) {
    var DOM = (function () {
        function DOM() {
        }
        DOM.ready = function (callback) {
            if (document.readyState != 'complete') {
                document.addEventListener('DOMContentLoaded', function () {
                    callback();
                });
            }
            else {
                callback();
            }
        };
        DOM.createElementFromHTML = function (htmlString) {
            var div = document.createElement('div');
            div.innerHTML = htmlString.trim();
            return div.firstChild;
        };
        return DOM;
    }());
    CO.DOM = DOM;
})(CO = exports.CO || (exports.CO = {}));
