"use strict";
exports.__esModule = true;
exports.COInViewport = void 0;
var COInViewport;
(function (COInViewport) {
    var Controller = (function () {
        function Controller() {
        }
        Controller.add = function (item) {
            this.items.push(item);
            this.init();
        };
        Controller.init = function () {
            if (this.initialized == false) {
                document.addEventListener('scroll', function () {
                    that.scroll();
                }, true);
                window.addEventListener('resize', function () {
                    that.scroll();
                }, true);
                Controller.startMutationObserver();
                this.initialized = true;
            }
            var that = this;
            this.waitAndStart();
        };
        Controller.waitAndStart = function () {
            var that = this;
            setTimeout(function () {
                if (document.readyState != 'complete') {
                    Controller.waitAndStart();
                }
                else {
                    Controller.scroll();
                }
            }, 100);
        };
        Controller.startMutationObserver = function () {
            this.mutationObserver = new MutationObserver(function () {
                Controller.scroll();
            });
            this.mutationObserver.observe(document.body, {
                attributes: true,
                subtree: true,
                attributeFilter: ['style', 'class']
            });
        };
        Controller.scroll = function () {
            for (var _i = 0, _a = this.items; _i < _a.length; _i++) {
                var item = _a[_i];
                if (item.isActive() == false) {
                    continue;
                }
                if (this.isElementVisible(item.getElement(), item.getVerticalShift(), true)) {
                    item.execute();
                }
            }
        };
        Controller.isElementVisible = function (el, shift, onlyvertical) {
            if (onlyvertical === void 0) { onlyvertical = true; }
            shift = shift || 0;
            var rect;
            try {
                rect = el.getBoundingClientRect();
            }
            catch (e) {
                return false;
            }
            var vWidth = window.innerWidth || document.documentElement.clientWidth;
            var vHeight = window.innerHeight || document.documentElement.clientHeight;
            var efp = function (x, y) {
                return document.elementFromPoint(x, y);
            };
            if (onlyvertical) {
                if (rect.bottom < 0 + shift
                    || rect.top > vHeight - shift)
                    return false;
                return COInViewport.Controller.isVisibleByParent(el);
            }
            else {
                if (rect.right < 0 || rect.bottom < 0 + shift
                    || rect.left > vWidth || rect.top > vHeight - shift)
                    return false;
                return COInViewport.Controller.isVisibleByParent(el);
            }
        };
        Controller.isVisibleByParent = function (el) {
            var visible = true;
            while (el.parentElement) {
                el = el.parentElement;
                var style = window.getComputedStyle(el);
                if (style.display == 'none') {
                    return false;
                }
            }
            return true;
        };
        Controller.VERSION = "1.0.1";
        Controller.items = Array();
        Controller.initialized = false;
        return Controller;
    }());
    COInViewport.Controller = Controller;
    var Item = (function () {
        function Item() {
            this._delay = 0;
            this._verticalShift = 0;
            this.alreadyExecuted = false;
            this.minTimeDistanceFrom = null;
            this.minTimeDistanceFromTime = null;
            this.active = true;
            this.startedAt = null;
        }
        Item.prototype.getStartedAt = function () {
            return this.startedAt;
        };
        Item.prototype.deactivate = function () {
            this.active = false;
        };
        Item.prototype.activate = function () {
            this.active = true;
            COInViewport.Controller.scroll();
        };
        Item.prototype.setMinTimeDistanceFrom = function (ivi, mtdft) {
            this.minTimeDistanceFrom = ivi;
            this.minTimeDistanceFromTime = mtdft;
            return this;
        };
        Item.prototype.getAlreadyExecuted = function () {
            return this.alreadyExecuted;
        };
        Item.prototype.isActive = function () {
            return this.active;
        };
        Item.prototype.execute = function () {
            var that = this;
            if (this.getAlreadyExecuted()) {
                return false;
            }
            if (this.minTimeDistanceFrom != null && this.minTimeDistanceFromTime != null) {
                var cd = new Date();
                if (this.minTimeDistanceFrom.getStartedAt() == null || cd.getTime() <= this.minTimeDistanceFrom.getStartedAt().getTime() + this.minTimeDistanceFromTime) {
                    setTimeout(function () {
                        that.execute();
                    }, 1);
                    return;
                }
            }
            this.setAlreadyExecuted(true);
            if (this.getDelay()) {
                setTimeout(function () {
                    that.startedAt = new Date();
                    that.getCallback().bind(that)();
                }, this.getDelay());
                return true;
            }
            else {
                this.startedAt = new Date();
                this.getCallback().bind(this)();
                return true;
            }
        };
        Item.prototype.setAlreadyExecuted = function (val) {
            this.alreadyExecuted = val;
            return this;
        };
        Item.prototype.getElement = function () {
            return this._element;
        };
        Item.prototype.setElement = function (value) {
            this._element = value;
            return this;
        };
        Item.prototype.getCallback = function () {
            return this._callback;
        };
        Item.prototype.setCallback = function (value) {
            this._callback = value;
            return this;
        };
        Item.prototype.getDelay = function () {
            return this._delay;
        };
        Item.prototype.setDelay = function (value) {
            this._delay = value;
            return this;
        };
        Item.prototype.getVerticalShift = function () {
            return this._verticalShift;
        };
        Item.prototype.setVerticalShift = function (value) {
            this._verticalShift = value;
            return this;
        };
        return Item;
    }());
    COInViewport.Item = Item;
})(COInViewport = exports.COInViewport || (exports.COInViewport = {}));
