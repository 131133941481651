"use strict";
exports.__esModule = true;
exports.CO = void 0;
var CO;
(function (CO) {
    var LightBox = (function () {
        function LightBox() {
            this.items = [];
            this._showPager = true;
            this._showControls = true;
            this.classes = [];
        }
        LightBox.prototype.addItem = function (url) {
            this.items.push(url);
        };
        LightBox.prototype.open = function (index) {
            if (index === void 0) { index = 0; }
            this.createSlideshow(index);
        };
        LightBox.prototype.handleEvent = function (event) {
            switch (event.type) {
                case 'keyup':
                    if (event.key == 'Escape') {
                        this.closeSlideshow();
                    }
                    if (event.key == 'ArrowRight') {
                        this.next();
                    }
                    if (event.key == 'ArrowLeft') {
                        this.prev();
                    }
                    break;
            }
        };
        LightBox.prototype.addClass = function (classname) {
            this.classes.push(classname);
        };
        LightBox.prototype.createSlideshow = function (index) {
            document.body.addEventListener('keyup', this);
            var that = this;
            this.overlay = document.createElement('div');
            this.overlay.classList.add('co-lightbox-overlay');
            this.classes.forEach(function (name) {
                that.overlay.classList.add(name);
            });
            this.slideshow = document.createElement('div');
            this.slideshow.classList.add('slideshow');
            this.overlay.appendChild(this.slideshow);
            this.items.forEach(function (el) {
                var item = document.createElement('div');
                item.classList.add('item');
                var img = document.createElement('img');
                img.src = el;
                item.appendChild(img);
                that.slideshow.appendChild(item);
            });
            if (this._showControls) {
                var prev = document.createElement('a');
                prev.classList.add('prev');
                prev.innerHTML = '<i class="fas fa-chevron-left"></i>';
                prev.addEventListener('click', function () {
                    that.prev();
                });
                var next = document.createElement('a');
                next.classList.add('next');
                next.innerHTML = '<i class="fas fa-chevron-right"></i>';
                next.addEventListener('click', function () {
                    that.next();
                });
                this.overlay.appendChild(prev);
                this.overlay.appendChild(next);
            }
            if (this._showPager) {
                this.pager = document.createElement('div');
                this.pager.classList.add('pager');
                this.overlay.appendChild(this.pager);
            }
            var close = document.createElement('a');
            close.innerHTML = '<i class="far fa-times"></i>';
            close.classList.add('close');
            close.addEventListener('click', function () {
                that.closeSlideshow();
                return false;
            });
            this.overlay.appendChild(close);
            document.body.appendChild(this.overlay);
            $(this.slideshow).cycle({
                speed: 600,
                timeout: 0,
                swipe: true,
                fx: 'scrollHorz',
                startingSlide: index,
                slides: ' > div',
                log: false
            });
            this.setPager($(this.slideshow).data("cycle.opts").currSlide, $(this.slideshow).data("cycle.opts").slideCount);
            setTimeout(function () {
                that.overlay.classList.add('show');
            }, 300);
        };
        LightBox.prototype.prev = function () {
            $(this.slideshow).cycle('prev');
            this.setPager($(this.slideshow).data("cycle.opts").currSlide, $(this.slideshow).data("cycle.opts").slideCount);
        };
        LightBox.prototype.next = function () {
            $(this.slideshow).cycle('next');
            this.setPager($(this.slideshow).data("cycle.opts").currSlide, $(this.slideshow).data("cycle.opts").slideCount);
        };
        LightBox.prototype.setPager = function (curr, all) {
            if (this.pager == null) {
                return;
            }
            curr += 1;
            this.pager.innerHTML = curr + ' / ' + all;
        };
        LightBox.prototype.closeSlideshow = function () {
            var that = this;
            this.overlay.classList.add('hide');
            document.body.removeEventListener('keyup', this);
            setTimeout(function () {
                that.overlay.remove();
            }, 300);
        };
        LightBox.prototype.showControls = function (b) {
            this._showControls = b;
        };
        LightBox.prototype.showPager = function (b) {
            this._showPager = b;
        };
        return LightBox;
    }());
    CO.LightBox = LightBox;
})(CO = exports.CO || (exports.CO = {}));
