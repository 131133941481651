"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.COLightBox = void 0;
var new_co_window_1 = require("./new-co.window");
var co_imageviewer_1 = require("./co.imageviewer");
var COLightBox;
(function (COLightBox) {
    var Controller = (function () {
        function Controller() {
            this.items = [];
            this._showPager = true;
            this._showControls = true;
            this.classes = [];
            this.openedCOImageViewer = null;
        }
        Controller.prototype.getItems = function () {
            return this.items;
        };
        Controller.prototype.addImage = function (url) {
            this.items.push(new Image(url));
        };
        Controller.prototype.addYoutubeVideo = function (id) {
            this.items.push(new YoutubeVideo(id));
        };
        Controller.prototype.open = function (index) {
            if (index === void 0) { index = 0; }
            this.createSlideshow(index);
        };
        Controller.prototype.handleEvent = function (event) {
            switch (event.type) {
                case 'keyup':
                    if (event.key == 'Escape') {
                        this.closeSlideshow();
                    }
                    if (event.key == 'ArrowRight') {
                        this.next();
                    }
                    if (event.key == 'ArrowLeft') {
                        this.prev();
                    }
                    break;
            }
        };
        Controller.prototype.addClass = function (classname) {
            this.classes.push(classname);
        };
        Controller.prototype.createSlideshow = function (index) {
            document.body.addEventListener('keyup', this);
            var lastCOWindowZIndex = new_co_window_1.COWindow.getLastZIndex() + 1;
            var that = this;
            this.overlay = document.createElement('div');
            this.overlay.classList.add('co-lightbox-overlay');
            this.overlay.style.zIndex = lastCOWindowZIndex.toString();
            this.classes.forEach(function (name) {
                that.overlay.classList.add(name);
            });
            this.slideshow = document.createElement('div');
            this.slideshow.classList.add('slideshow');
            this.overlay.appendChild(this.slideshow);
            this.items.forEach(function (el) {
                var item = document.createElement('div');
                item.classList.add('item');
                switch (true) {
                    case el instanceof Image:
                        var img = document.createElement('img');
                        img.src = el.getURL();
                        item.appendChild(img);
                        that.addImageViewer(img, item);
                        break;
                    case el instanceof YoutubeVideo:
                        item.appendChild(el.getEmbedCode());
                        break;
                }
                that.slideshow.appendChild(item);
            });
            if (this._showControls && this.items.length > 1) {
                var prev = document.createElement('a');
                prev.classList.add('prev');
                prev.innerHTML = '<i class="fas fa-chevron-left"></i>';
                prev.addEventListener('click', function () {
                    that.prev();
                });
                var next = document.createElement('a');
                next.classList.add('next');
                next.innerHTML = '<i class="fas fa-chevron-right"></i>';
                next.addEventListener('click', function () {
                    that.next();
                });
                this.overlay.appendChild(prev);
                this.overlay.appendChild(next);
            }
            if (this._showPager && this.items.length > 1) {
                this.pager = document.createElement('div');
                this.pager.classList.add('pager');
                this.overlay.appendChild(this.pager);
            }
            var close = document.createElement('a');
            close.innerHTML = '<i class="far fa-times"></i>';
            close.classList.add('close');
            close.addEventListener('click', function () {
                that.closeSlideshow();
                return false;
            });
            this.overlay.appendChild(close);
            document.body.appendChild(this.overlay);
            $(this.slideshow).cycle({
                speed: 600,
                timeout: 0,
                swipe: true,
                fx: 'scrollHorz',
                startingSlide: index,
                cycleSwipe: true,
                slides: ' > div',
                log: false
            });
            $(this.slideshow).on('cycle-after', function (event, optionHash, outgoingSlideEl, incomingSlideEl, forwardFlag) {
                this.setPager($(this.slideshow).data("cycle.opts").currSlide, $(this.slideshow).data("cycle.opts").slideCount);
            }.bind(this));
            this.setPager($(this.slideshow).data("cycle.opts").currSlide, $(this.slideshow).data("cycle.opts").slideCount);
            setTimeout(function () {
                that.overlay.classList.add('show');
                that.previousOverflowValue = document.body.style.overflow;
                document.body.style.overflow = 'hidden';
            }, 300);
        };
        Controller.prototype.addImageViewer = function (img, item) {
            var that = this;
            var buttonZoom = document.createElement('a');
            buttonZoom.classList.add('zoom');
            buttonZoom.innerHTML = '<i class="fal fa-search-plus"></i>';
            buttonZoom.addEventListener('click', function () {
                that.openedCOImageViewer = new co_imageviewer_1.COImageviewer(img.src, function () {
                    that.openedCOImageViewer = null;
                });
            });
            item.appendChild(buttonZoom);
        };
        Controller.prototype.prev = function () {
            $(this.slideshow).cycle('prev');
        };
        Controller.prototype.next = function () {
            $(this.slideshow).cycle('next');
        };
        Controller.prototype.setPager = function (curr, all) {
            if (this.pager == null) {
                return;
            }
            curr += 1;
            this.pager.innerHTML = curr + ' / ' + all;
        };
        Controller.prototype.closeSlideshow = function () {
            var that = this;
            this.overlay.classList.add('hide');
            document.body.removeEventListener('keyup', this);
            setTimeout(function () {
                if (that.openedCOImageViewer) {
                    that.openedCOImageViewer.close();
                }
                that.overlay.remove();
                document.body.style.overflow = that.previousOverflowValue;
            }, 300);
        };
        Controller.prototype.showControls = function (b) {
            this._showControls = b;
        };
        Controller.prototype.showPager = function (b) {
            this._showPager = b;
        };
        return Controller;
    }());
    COLightBox.Controller = Controller;
    var Item = (function () {
        function Item() {
        }
        return Item;
    }());
    var Image = (function (_super) {
        __extends(Image, _super);
        function Image(url) {
            var _this = _super.call(this) || this;
            _this.url = url;
            return _this;
        }
        Image.prototype.getURL = function () {
            return this.url;
        };
        return Image;
    }(Item));
    COLightBox.Image = Image;
    var YoutubeVideo = (function (_super) {
        __extends(YoutubeVideo, _super);
        function YoutubeVideo(id) {
            var _this = _super.call(this) || this;
            _this.id = id;
            return _this;
        }
        YoutubeVideo.prototype.getID = function () {
            return this.id;
        };
        YoutubeVideo.prototype.getEmbedCode = function () {
            var content = document.createElement('iframe');
            content.src = 'https://www.youtube.com/embed/' + this.getID();
            content.allowFullscreen = true;
            content.frameBorder = "0";
            content.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture');
            return content;
        };
        return YoutubeVideo;
    }(Item));
    COLightBox.YoutubeVideo = YoutubeVideo;
})(COLightBox = exports.COLightBox || (exports.COLightBox = {}));
