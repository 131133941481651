"use strict";
exports.__esModule = true;
exports.COImageviewer = void 0;
var new_co_dom_1 = require("./new-co.dom");
var pinch_zoom_js_1 = require("./pinch-zoom-js");
var COImageviewer = (function () {
    function COImageviewer(url, onclose) {
        if (onclose === void 0) { onclose = null; }
        this.template = "\n        <div class=\"co-zoomable-imageviewer\">\n            <img src=\"\" alt=\"\">\n            <a href=\"\" class=\"close\"><i class=\"far fa-times\"></i></a>\n        </div>\n    ";
        var that = this;
        this.container = new_co_dom_1.CODOM.createElementFromHTML(this.template);
        this.container.querySelector('img').src = url;
        document.body.appendChild(this.container);
        this.container.querySelector('a.close').addEventListener('click', function (event) {
            event.preventDefault();
            that.close(onclose);
        });
        new pinch_zoom_js_1["default"](this.container.querySelector('img'));
        setTimeout(function () {
            that.container.classList.add('show');
        }, 50);
    }
    COImageviewer.prototype.close = function (onclose) {
        if (onclose === void 0) { onclose = null; }
        var that = this;
        this.container.classList.add('hide');
        setTimeout(function () {
            that.container.remove();
            if (onclose) {
                onclose();
            }
        }, 200);
    };
    return COImageviewer;
}());
exports.COImageviewer = COImageviewer;
