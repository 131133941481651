"use strict";
exports.__esModule = true;
exports.COURLNavigator = void 0;
var COURLNavigator;
(function (COURLNavigator) {
    var Controller = (function () {
        function Controller() {
        }
        Controller.init = function (element) {
            if (element === void 0) { element = null; }
            history.scrollRestoration = 'manual';
            this.currentUrl = window.location.pathname;
            if (Controller.popListenerAdded == false) {
                window.addEventListener('popstate', function (event) {
                    if (Controller.currentUrl == window.location.pathname) {
                        Controller.handleHash();
                        return false;
                    }
                    Controller.loadContent(location.pathname, false, event.state ? event.state.scrollTop : null);
                });
                Controller.popListenerAdded = true;
                Controller.addMutationObserver();
            }
            Controller.handleHash();
            if (element != null) {
                Controller.initForA(element);
            }
            else {
                var as = $('a.ajax:not(.urlnavigator-initialized)');
                as.each(function () {
                    Controller.initForA(this);
                });
            }
        };
        Controller.getLastEventOrigin = function () {
            return this.lastEventOrigin;
        };
        Controller.addMutationObserver = function () {
            var config = { childList: true, subtree: true, attributes: true };
            this.mutationObserver = new MutationObserver(function (mutationsList) {
                Controller.mutationObserver.disconnect();
                Controller.init();
                Controller.addMutationObserver();
            });
            Controller.mutationObserver.observe(document, config);
        };
        Controller.handleHash = function () {
            if (this.hashHandled) {
                return;
            }
            this.hashHandled = true;
            var hash = window.location.hash;
            if (hash.length != undefined && hash.length > 0) {
                setTimeout(function () {
                    Controller.gotoBlock(hash.substr(1));
                }, 300);
            }
            else {
            }
        };
        Controller.gotoBlock = function (to, tempVerticalShift) {
            if (tempVerticalShift === void 0) { tempVerticalShift = 0; }
            if (to.indexOf('#') !== -1) {
                to = to.substr(to.indexOf('#') + 1);
            }
            var el = document.querySelector('[data-block=' + to + ']');
            this.scrollToElement(el, Controller.scrollShift, 300, tempVerticalShift);
        };
        Controller.scrollToElement = function (el, verticalShift, time, tempVerticalShift) {
            if (verticalShift === void 0) { verticalShift = 0; }
            if (time === void 0) { time = 300; }
            if (tempVerticalShift === void 0) { tempVerticalShift = 0; }
            if (time == 0) {
                $('html,body').scrollTop(Controller.getElementPosition(el) - verticalShift + tempVerticalShift);
                return;
            }
            var page = $('html,body');
            page.animate({
                scrollTop: Controller.getElementPosition(el) - verticalShift + tempVerticalShift
            }, time, function () {
            });
            setTimeout(function () {
                page.stop();
            }, time + 10);
            return true;
        };
        Controller.setScrollShift = function (value) {
            Controller.scrollShift = value;
        };
        Controller.initForA = function (el) {
            var that = this;
            if ($(el).hasClass('urlnavigator-initialized')) {
                return;
            }
            if ($(el).attr('onclick') === undefined || $(el).attr('onclick').trim() == '') {
                $(el).addClass('urlnavigator-initialized');
                $(el).on('click', function () {
                    if (that.onClick) {
                        that.onClick(this);
                    }
                    if ($(this).attr('href') !== undefined
                        && (($(this).attr('href').indexOf('http://') === -1
                            &&
                                $(this).attr('href').indexOf('https://') === -1)
                            || ($(this).attr('href').indexOf('http://') !== -1
                                && $(this).attr('href').indexOf('http://' + window.location.host) !== -1))) {
                        var to = $(this).attr('href');
                        if (to.indexOf('#') == 0) {
                            to = to.substr(1);
                            history.pushState(null, null, $(this).attr('href'));
                            Controller.gotoBlock(to);
                            return false;
                        }
                        if (to.indexOf('#') !== -1) {
                            var path = to.substr(0, to.indexOf('#'));
                            if (window.location.pathname === path) {
                                history.pushState(null, null, $(this).attr('href'));
                                Controller.gotoBlock(to.substr(to.indexOf('#') + 1));
                                return false;
                            }
                        }
                        else {
                            if (window.location.pathname === to) {
                                Controller.gotoBlock('top');
                                return false;
                            }
                        }
                        if ($(this).attr('target') == '_blank') {
                            return true;
                        }
                        var url = $(this).attr('href');
                        Controller.loadContent(url, true, null, EventOrigin.onclick);
                        return false;
                    }
                    else {
                        return true;
                    }
                });
            }
        };
        Controller.loadContent = function (url, addEntry, scrollTo, eventOrigin) {
            if (addEntry === void 0) { addEntry = true; }
            if (scrollTo === void 0) { scrollTo = null; }
            if (eventOrigin === void 0) { eventOrigin = EventOrigin.script; }
            var that = this;
            if (this.onStart != null) {
                this.onStart();
            }
            var direction = addEntry ? Direction.forward : Direction.backward;
            this.lastEventOrigin = eventOrigin;
            Controller.currentUrl = url;
            $.ajax(url, {
                dataType: 'json',
                success: function (data) {
                    if (data.hasOwnProperty('redirect')) {
                        window.location.href = data.redirect;
                        return;
                    }
                    var scrollTop = $(window).scrollTop();
                    Controller.hashHandled = false;
                    that.onSuccess(data, direction, function (block) {
                        if (block === void 0) { block = null; }
                        if (addEntry == true) {
                            history.replaceState({ scrollTop: scrollTop }, document.title, location.href);
                            history.pushState(null, document.title, url);
                        }
                        if (scrollTo) {
                            window.scrollTo(0, scrollTo);
                        }
                        else {
                            if (window.location.hash == '') {
                                if (block) {
                                    COURLNavigator.Controller.gotoBlock(block);
                                }
                                else {
                                    $('html, body').animate({ scrollTop: 0 }, 200);
                                }
                            }
                            else {
                                setTimeout(function () {
                                    console.log(window.location.hash.substr(1));
                                    COURLNavigator.Controller.gotoBlock(window.location.hash.substr(1));
                                }, 500);
                            }
                        }
                    });
                }
            });
        };
        Controller.setURL = function (url) {
            history.replaceState(null, null, url);
        };
        Controller.rem = function (base) {
            if (base === void 0) { base = null; }
            if (base == null) {
                base = 2;
            }
            var html = document.getElementsByTagName('html')[0];
            return parseFloat(window.getComputedStyle(html)['fontSize']) / base;
        };
        Controller.setOnSuccess = function (callback) {
            Controller.onSuccess = callback;
        };
        Controller.setOnClick = function (func) {
            Controller.onClick = func;
        };
        Controller.setOnStart = function (func) {
            Controller.onStart = func;
        };
        Controller.changeLayoutClass = function (classname) {
            var dclasses = Array.prototype.slice.call(document.body.classList);
            var re = /layout-/;
            dclasses.forEach(function (c) {
                if (c.match(re)) {
                    if (classname !== c) {
                        document.body.classList.remove(c);
                    }
                    else {
                    }
                }
            });
            document.body.classList.add(classname);
        };
        Controller.getLayoutClass = function () {
            var current = null;
            var dclasses = Array.prototype.slice.call(document.body.classList);
            var re = /layout-/;
            dclasses.forEach(function (c) {
                if (c.match(re)) {
                    current = c;
                }
            });
            return current;
        };
        Controller.changePageClasses = function (pages) {
            var dclasses = Array.prototype.slice.call(document.body.classList);
            var re = /page-/;
            dclasses.forEach(function (c) {
                if (c.match(re)) {
                    if (pages.indexOf(c) !== -1) {
                    }
                    else {
                        document.body.classList.remove(c);
                    }
                }
            });
            for (var _i = 0, pages_1 = pages; _i < pages_1.length; _i++) {
                var cl = pages_1[_i];
                document.body.classList.add(cl);
            }
        };
        Controller.getPageClasses = function () {
            var ret = [];
            var dclasses = Array.prototype.slice.call(document.body.classList);
            var re = /page-/;
            dclasses.forEach(function (c) {
                if (c.match(re)) {
                    ret.push(c);
                }
            });
            return ret;
        };
        Controller.popListenerAdded = false;
        Controller.scrollShift = 0;
        Controller.hashHandled = false;
        Controller.getElementPosition = function (elem) {
            if (elem === void 0) { elem = null; }
            if (elem == null) {
                return 0;
            }
            var location = 0;
            if (elem.offsetParent) {
                do {
                    location += elem.offsetTop;
                    elem = elem.offsetParent;
                } while (elem);
            }
            return location >= 0 ? location : 0;
        };
        return Controller;
    }());
    COURLNavigator.Controller = Controller;
    ;
    var Direction;
    (function (Direction) {
        Direction[Direction["forward"] = 0] = "forward";
        Direction[Direction["backward"] = 1] = "backward";
    })(Direction = COURLNavigator.Direction || (COURLNavigator.Direction = {}));
    var EventOrigin;
    (function (EventOrigin) {
        EventOrigin[EventOrigin["script"] = 0] = "script";
        EventOrigin[EventOrigin["onclick"] = 1] = "onclick";
    })(EventOrigin = COURLNavigator.EventOrigin || (COURLNavigator.EventOrigin = {}));
    var ScrollShiftCalculator = (function () {
        function ScrollShiftCalculator() {
        }
        ScrollShiftCalculator.prototype.setOnDesktop = function (f) {
            this.onDesktop = f;
            return this;
        };
        ScrollShiftCalculator.prototype.setOnLandscapeTablet = function (f) {
            this.onLandscapeTablet = f;
            return this;
        };
        ScrollShiftCalculator.prototype.setOnPortraitTablet = function (f) {
            this.onPortraitTablet = f;
            return this;
        };
        ScrollShiftCalculator.prototype.setOnLandscapePhone = function (f) {
            this.onLandscapePhone = f;
            return this;
        };
        ScrollShiftCalculator.prototype.setOnPortraitPhone = function (f) {
            this.onPortraitPhone = f;
            return this;
        };
        ScrollShiftCalculator.prototype.calculate = function () {
            if (this.isPortrait()) {
                if (window.innerWidth < 575.98) {
                    if (this.onPortraitPhone != null) {
                        return this.onPortraitPhone();
                    }
                }
                if (window.innerWidth < 1024) {
                    if (this.onPortraitTablet != null) {
                        return this.onPortraitTablet();
                    }
                }
                return this.onDesktop();
            }
            else {
                if (window.innerHeight < 575.98) {
                    if (this.onLandscapePhone != null) {
                        return this.onLandscapePhone();
                    }
                }
                if (window.innerHeight < 1024) {
                    if (this.onLandscapeTablet != null) {
                        return this.onLandscapeTablet();
                    }
                }
                return this.onDesktop();
            }
        };
        ScrollShiftCalculator.prototype.isPortrait = function () {
            return window.matchMedia("(orientation: portrait)").matches;
        };
        return ScrollShiftCalculator;
    }());
    COURLNavigator.ScrollShiftCalculator = ScrollShiftCalculator;
})(COURLNavigator = exports.COURLNavigator || (exports.COURLNavigator = {}));
