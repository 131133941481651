"use strict";
exports.__esModule = true;
exports.COLightBox = exports.COImageLoader = exports.CODOM = exports.Webapp = void 0;
var COD = require("../../../../../controller/static/ts/co.dom");
var CODOM = COD.CO.DOM;
exports.CODOM = CODOM;
var webapp_1 = require("./components/webapp");
exports.Webapp = webapp_1.Webapp;
var COIL = require("../../../../../controller/static/ts/co.imageloader");
var COImageLoader = COIL.CO.ImageLoader;
exports.COImageLoader = COImageLoader;
var custom_elements_1 = require("../../../../../controller/static/ts/custom-elements");
var co_image_1 = require("../../../../../controller/static/ts/co-image");
var COL = require("../../../../../controller/static/ts/co-lightbox");
var COLightBox = COL.CO.LightBox;
exports.COLightBox = COLightBox;
custom_elements_1.CustomElements.Controller.add('co-image', co_image_1.COImage);
var webapp;
CODOM.ready(function () {
    webapp = new webapp_1.Webapp();
});
