"use strict";
exports.__esModule = true;
exports.CO = void 0;
var CO;
(function (CO) {
    var ImageLoader = (function () {
        function ImageLoader() {
            this.images = [];
            this.onSuccess = null;
            this.imgs = [];
        }
        ImageLoader.prototype.add = function (url, onSuccess) {
            if (onSuccess === void 0) { onSuccess = null; }
            var item = new ImageLoaderItem();
            item.url = url;
            item.onSuccess = onSuccess;
            this.images.push(item);
            return this;
        };
        ImageLoader.prototype.execute = function () {
            this.download();
        };
        ImageLoader.prototype.setOnSuccess = function (callback) {
            this.onSuccess = callback;
            return this;
        };
        ImageLoader.prototype.download = function () {
            if (this.images.length == 0) {
                if (this.onSuccess != null) {
                    this.onSuccess();
                }
                return;
            }
            var that = this;
            var item = this.images.shift();
            var img = new Image();
            img.src = item.url;
            img.onload = function () {
                if (item.onSuccess != null) {
                    item.onSuccess(item.url);
                }
                that.download();
            };
            img.onerror = function (e) {
                that.download();
            };
            this.imgs.push(img);
        };
        ImageLoader.prototype.getImgs = function () {
            return this.imgs;
        };
        return ImageLoader;
    }());
    CO.ImageLoader = ImageLoader;
    var ImageLoaderItem = (function () {
        function ImageLoaderItem() {
        }
        return ImageLoaderItem;
    }());
    CO.ImageLoaderItem = ImageLoaderItem;
    ;
})(CO = exports.CO || (exports.CO = {}));
