"use strict";
exports.__esModule = true;
exports.Webapp = void 0;
var COIV = require("../../../../../../controller/static/ts/co.inviewport");
var COInViewport = COIV.CO.InViewport;
var COIL = require("../../../../../../controller/static/ts/co.imageloader");
var COImageLoader = COIL.CO.ImageLoader;
var co_urlnavigator_1 = require("../../../../../../controller/static/ts/co.urlnavigator");
var Webapp = (function () {
    function Webapp() {
        this.init();
        this.initScrollListenerForHeader();
        Webapp.initTooltipster();
        this.showPopup();
    }
    Webapp.initTooltipster = function () {
        $('.tooltip').tooltipster({
            theme: 'tooltipster-noir'
        });
    };
    Webapp.prototype.initScrollListenerForHeader = function () {
        var that = this;
        document.addEventListener('scroll', function () {
            var scrollT = $('body').scrollTop() || $(window).scrollTop();
            if (scrollT > co_urlnavigator_1.COURLNavigator.Controller.rem() * 10) {
                $('body').addClass('small-header');
            }
            else {
                $('body').removeClass('small-header');
            }
        });
    };
    Webapp.prototype.init = function () {
        var that = this;
        window.addEventListener('scroll', function () {
            COInViewport.scroll();
            var doc = document.body;
            var left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
            var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
            if (top > (document.body.clientHeight - window.innerHeight) * .7) {
                document.body.classList.add('up');
            }
            else {
                document.body.classList.remove('up');
            }
        });
        co_urlnavigator_1.COURLNavigator.Controller.init();
        co_urlnavigator_1.COURLNavigator.Controller.setScrollShift(co_urlnavigator_1.COURLNavigator.Controller.rem() * 10);
        co_urlnavigator_1.COURLNavigator.Controller.setOnStart(function (data) {
            var l = document.createElement('div');
            l.classList.add('loader');
            document.body.appendChild(l);
            document.body.classList.add('loading');
            Webapp.closeMobilePanel();
        });
        co_urlnavigator_1.COURLNavigator.Controller.setOnSuccess(function (data, direction, finishedCallback) {
            if (finishedCallback === void 0) { finishedCallback = null; }
            document.body.classList.remove('loading');
            if (that.currentLayout == data.layout) {
                switch (data.layout) {
                    case 'layout-left-aside':
                        Webapp.switchLayoutLeftAside(data);
                        break;
                }
                co_urlnavigator_1.COURLNavigator.Controller.changePageClasses(data.classes);
            }
            else {
                $('.ajax-container').addClass('loading');
                co_urlnavigator_1.COURLNavigator.Controller.changePageClasses(data.classes);
                co_urlnavigator_1.COURLNavigator.Controller.changeLayoutClass(data.layout);
                $('.content-container').html(data.fullContent);
                Webapp.initTooltipster();
                if (finishedCallback) {
                    finishedCallback();
                }
                co_urlnavigator_1.COURLNavigator.Controller.init();
            }
        });
    };
    Webapp.switchLayoutLeftAside = function (data) {
        var header = document.querySelector('.layout.header .inner');
        var headerText = header.querySelector('div');
        var div1 = header.querySelector('div');
        var div2 = document.createElement('div');
        header.appendChild(div2);
        div2.innerHTML = data.title;
        div2.classList.add('new');
        div1.classList.add('out');
        Array.prototype.slice.call(document.querySelectorAll('[data-menu-item-id')).forEach(function (el) {
            el.classList.remove('current');
        });
        if (data.menuItemID) {
            Array.prototype.slice.call(document.querySelectorAll("[data-menu-item-id='" + data.menuItemID + "']")).forEach(function (el) {
                el.classList.add('current');
            });
        }
        if (data.image) {
            new COImageLoader().add(data.image, function (url) {
                (document.querySelector('.layout.header .image')).style.backgroundImage = 'url(' + url + ')';
            }).setOnSuccess(function () {
                document.querySelector('.layout.header .image').classList.add('show');
            }).execute();
        }
        $('.layout.content').html(data.content);
        Webapp.initTooltipster();
        setTimeout(function () {
            div1.remove();
            div2.classList.remove('new');
        }, 300);
    };
    Webapp.toggleMobilePanel = function () {
        document.body.classList.toggle('mobile-panel-opened');
    };
    Webapp.closeMobilePanel = function () {
        document.body.classList.remove('mobile-panel-opened');
    };
    Webapp.moveInStartPageItems = function () {
        document.querySelector('body .cc').classList.add('show');
    };
    Webapp.prototype.showPopup = function () {
        if (document.querySelector('.popup') == null) {
            return;
        }
        if (window.sessionStorage != null) {
            if (window.sessionStorage.getItem('popup') != null) {
                return;
            }
        }
        setTimeout(function () {
            if (window.sessionStorage != null) {
                window.sessionStorage.setItem('popup', '1');
            }
            document.querySelector('.popup').classList.add('show');
            setTimeout(function () {
                document.querySelector('.popup .overlay').classList.add('show');
                setTimeout(function () {
                    document.querySelector('.popup .content').classList.add('show');
                }, 300);
                document.querySelector('.popup a.close').addEventListener('click', function (event) {
                    document.querySelector('.popup').classList.remove('show');
                    setTimeout(function () {
                        document.querySelector('.popup').remove();
                    }, 300);
                    event.preventDefault();
                    return false;
                });
            }, 100);
        }, 3000);
    };
    return Webapp;
}());
exports.Webapp = Webapp;
