"use strict";
exports.__esModule = true;
exports.CO = void 0;
var CO;
(function (CO) {
    var InViewport = (function () {
        function InViewport() {
        }
        InViewport.add = function (item) {
            this.items.push(item);
            this.init();
        };
        InViewport.init = function () {
            if (this.initialized == false) {
                document.addEventListener('scroll', function () {
                    that.scroll();
                }, true);
                this.initialized = true;
            }
            var that = this;
            this.waitAndStart();
        };
        InViewport.waitAndStart = function () {
            setTimeout(function () {
                if (document.readyState != 'complete') {
                    InViewport.waitAndStart();
                }
                else {
                    InViewport.scroll();
                }
            }, 100);
        };
        InViewport.scroll = function () {
            for (var _i = 0, _a = this.items; _i < _a.length; _i++) {
                var item = _a[_i];
                if (this.isElementVisible(item.getElement(), item.getVerticalShift(), true)) {
                    item.execute();
                }
            }
        };
        InViewport.isElementVisible = function (el, shift, onlyvertical) {
            if (onlyvertical === void 0) { onlyvertical = false; }
            shift = shift || 0;
            var rect;
            try {
                rect = el.getBoundingClientRect();
            }
            catch (e) {
                return false;
            }
            var vWidth = window.innerWidth || document.documentElement.clientWidth;
            var vHeight = window.innerHeight || document.documentElement.clientHeight;
            var efp = function (x, y) {
                return document.elementFromPoint(x, y);
            };
            if (onlyvertical) {
                if (rect.bottom < 0 + shift
                    || rect.top > vHeight - shift)
                    return false;
                return true;
            }
            else {
                if (rect.right < 0 || rect.bottom < 0 + shift
                    || rect.left > vWidth || rect.top > vHeight - shift)
                    return false;
                return true;
            }
        };
        InViewport.rem = function (base) {
            if (base === void 0) { base = null; }
            if (base == null) {
                base = 2;
            }
            var html = document.getElementsByTagName('html')[0];
            return parseFloat(window.getComputedStyle(html)['fontSize']) / base;
        };
        InViewport.VERSION = "1.0.1";
        InViewport.items = Array();
        InViewport.initialized = false;
        return InViewport;
    }());
    CO.InViewport = InViewport;
    var InViewportItem = (function () {
        function InViewportItem() {
            this._delay = 0;
            this._verticalShift = 0;
            this.alreadyExecuted = false;
            this.minTimeDistanceFrom = null;
            this.minTimeDistanceFromTime = null;
            this.startedAt = null;
        }
        InViewportItem.prototype.getStartedAt = function () {
            return this.startedAt;
        };
        InViewportItem.prototype.setMinTimeDistanceFrom = function (ivi, mtdft) {
            this.minTimeDistanceFrom = ivi;
            this.minTimeDistanceFromTime = mtdft;
            return this;
        };
        InViewportItem.prototype.getAlreadyExecuted = function () {
            return this.alreadyExecuted;
        };
        InViewportItem.prototype.execute = function () {
            var that = this;
            if (this.getAlreadyExecuted()) {
                return false;
            }
            if (this.minTimeDistanceFrom != null && this.minTimeDistanceFromTime != null) {
                var cd = new Date();
                if (this.minTimeDistanceFrom.getStartedAt() == null || cd.getTime() <= this.minTimeDistanceFrom.getStartedAt().getTime() + this.minTimeDistanceFromTime) {
                    setTimeout(function () {
                        that.execute();
                    }, 1);
                    return;
                }
            }
            this.setAlreadyExecuted(true);
            if (this.getDelay()) {
                setTimeout(function () {
                    that.startedAt = new Date();
                    that.getCallback().bind(that)();
                }, this.getDelay());
                return true;
            }
            else {
                this.startedAt = new Date();
                this.getCallback().bind(this)();
                return true;
            }
        };
        InViewportItem.prototype.setAlreadyExecuted = function (val) {
            this.alreadyExecuted = val;
            return this;
        };
        InViewportItem.prototype.getElement = function () {
            return this._element;
        };
        InViewportItem.prototype.setElement = function (value) {
            this._element = value;
            return this;
        };
        InViewportItem.prototype.getCallback = function () {
            return this._callback;
        };
        InViewportItem.prototype.setCallback = function (value) {
            this._callback = value;
            return this;
        };
        InViewportItem.prototype.getDelay = function () {
            return this._delay;
        };
        InViewportItem.prototype.setDelay = function (value) {
            this._delay = value;
            return this;
        };
        InViewportItem.prototype.getVerticalShift = function () {
            return this._verticalShift;
        };
        InViewportItem.prototype.setVerticalShift = function (value) {
            this._verticalShift = value;
            return this;
        };
        return InViewportItem;
    }());
    CO.InViewportItem = InViewportItem;
})(CO = exports.CO || (exports.CO = {}));
